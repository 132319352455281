import React from 'react';

const Cover = () => {
  return (
    <div className="open-cover">

    </div>
  )
}

export default Cover;
